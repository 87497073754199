import React from 'react';

import Termly from 'src/components/Termly';
import Layout from 'src/layout/Layout';
import AnalyticsScript from 'src/components/Analytics';

const tos = () => {
  return (
    <Layout>
      <Termly dataid="a44ee496-ec55-4780-8b29-867f058e0569" />
      <AnalyticsScript />
    </Layout>
  );
};

export default tos;
